// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  appUrl: 'https://your_qa_endpoint/',

  apiBase: {
    url: 'https://api-qa.zonarsystems.net/gtcx-trip-api/v1/trip-zone'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net'
  },

  userPreferencesApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: 'jOmo6w4m6LPu35ttLLTopMeJcAigpztl',
    domain: 'zonar-qa.auth0.com',
    audience: 'http://apiqa.zonarsystems.net/',
    applicationId: 'de878185-9fd7-43c7-8b83-a52004df7f5e',
    defaultZonarRole: '1c9e174d-d8b8-42f3-a8cd-36f1d6dc1f0d',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_965ab5b6_4e0b_4f79_bd29_a7aa463039cb_2e9abd0'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'a9c074c5-b9e7-4c78-9ec5-9380eeaeba5a',
    clientToken: 'pub5725211aeea4a4f42e976e6b76562c75',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: [
      'en-US',
      'de-DE',
      'en-GB',
      'fr-FR',
      'es-ES',
      'it-IT',
      'en-CA'
    ],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
